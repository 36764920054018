
  export default [
    "abort",
"about",
"above",
"abuse",
"actor",
"admit",
"adopt",
"adore",
"adult",
"adult",
"after",
"again",
"agent",
"agree",
"ahead",
"aisle",
"alarm",
"alarm",
"album",
"alert",
"alien",
"alike",
"alive",
"alley",
"allow",
"alone",
"alone",
"along",
"altar",
"alter",
"amaze",
"among",
"amuse",
"angel",
"anger",
"angle",
"angry",
"ankle",
"annoy",
"apart",
"apart",
"apple",
"apply",
"argue",
"armed",
"aside",
"attic",
"avoid",
"await",
"awake",
"award",
"aware",
"awful",
"awful",
"bacon",
"badge",
"badly",
"bagel",
"basic",
"basis",
"bathe",
"beach",
"beard",
"beast",
"begin",
"being",
"belly",
"below",
"bench",
"bible",
"bingo",
"birth",
"bitch",
"bitch",
"black",
"blade",
"blame",
"blame",
"blast",
"blast",
"bleed",
"blend",
"bless",
"blind",
"blink",
"block",
"block",
"blond",
"blood",
"blues",
"bluff",
"board",
"board",
"boner",
"bonus",
"booth",
"booty",
"booze",
"bored",
"bowel",
"boxer",
"brain",
"brand",
"brave",
"bravo",
"bread",
"break",
"break",
"breed",
"bribe",
"brick",
"bride",
"brief",
"bring",
"broad",
"brown",
"brush",
"buddy",
"build",
"bully",
"bully",
"bunch",
"bunny",
"burst",
"buyer",
"cabin",
"cable",
"camel",
"candy",
"carry",
"carve",
"catch",
"catch",
"cater",
"cause",
"cause",
"cease",
"chain",
"chair",
"chalk",
"chaos",
"charm",
"chart",
"chase",
"chase",
"cheap",
"cheat",
"check",
"check",
"cheek",
"cheer",
"chess",
"chest",
"chick",
"chief",
"child",
"chili",
"chill",
"chimp",
"choir",
"choke",
"chore",
"cigar",
"civil",
"claim",
"class",
"clean",
"clean",
"clear",
"clear",
"click",
"cliff",
"climb",
"clock",
"clone",
"close",
"close",
"cloud",
"clown",
"coach",
"coach",
"coast",
"cobra",
"cocky",
"cocoa",
"colon",
"color",
"color",
"comic",
"comic",
"condo",
"couch",
"cough",
"cough",
"count",
"count",
"court",
"cover",
"cover",
"crack",
"crack",
"crank",
"crash",
"crawl",
"crazy",
"cream",
"crime",
"cross",
"cross",
"crowd",
"crown",
"cruel",
"crush",
"curly",
"curse",
"curse",
"cutie",
"cycle",
"daily",
"dairy",
"dance",
"dance",
"death",
"decaf",
"delay",
"demon",
"devil",
"diary",
"diner",
"dirty",
"disco",
"ditch",
"dizzy",
"dodge",
"donor",
"doubt",
"doubt",
"dough",
"dozen",
"drain",
"drain",
"drama",
"dream",
"dream",
"dress",
"dress",
"drill",
"drill",
"drink",
"drink",
"drive",
"drive",
"drown",
"drunk",
"dryer",
"dummy",
"dutch",
"early",
"early",
"earth",
"eight",
"elbow",
"elect",
"empty",
"enemy",
"enjoy",
"enter",
"entry",
"equal",
"equal",
"erase",
"error",
"essay",
"event",
"every",
"exact",
"exist",
"expel",
"extra",
"extra",
"fairy",
"faith",
"false",
"fancy",
"fatso",
"fatty",
"fault",
"favor",
"feces",
"fella",
"fence",
"fetch",
"fetus",
"fever",
"field",
"fifth",
"fifty",
"fight",
"fight",
"filth",
"final",
"first",
"flame",
"flash",
"flash",
"flesh",
"flirt",
"float",
"flood",
"floor",
"floss",
"fluid",
"flush",
"flute",
"flyer",
"focus",
"focus",
"folks",
"force",
"force",
"forth",
"forty",
"found",
"frame",
"frame",
"fraud",
"freak",
"freak",
"fresh",
"fried",
"front",
"front",
"fruit",
"fudge",
"fully",
"funky",
"funny",
"fuzzy",
"ghost",
"giant",
"giant",
"glass",
"glory",
"glove",
"gnome",
"goods",
"goose",
"grace",
"grade",
"grade",
"grand",
"grand",
"grant",
"grape",
"grass",
"grave",
"gravy",
"great",
"green",
"greet",
"grief",
"grill",
"grind",
"groin",
"groom",
"gross",
"group",
"grown",
"guard",
"guard",
"guess",
"guess",
"guest",
"guide",
"guide",
"guilt",
"habit",
"hairy",
"handy",
"happy",
"harsh",
"haunt",
"heart",
"heavy",
"hello",
"hoard",
"hobby",
"honey",
"honor",
"honor",
"horny",
"horse",
"hotel",
"house",
"howdy",
"human",
"human",
"humor",
"hurry",
"hurry",
"ideal",
"idiot",
"image",
"imply",
"inner",
"irony",
"issue",
"jeans",
"jelly",
"jerky",
"joint",
"judge",
"judge",
"juice",
"juicy",
"karma",
"kiddo",
"kitty",
"knife",
"knock",
"knock",
"label",
"label",
"labor",
"large",
"laser",
"latte",
"laugh",
"laugh",
"layer",
"learn",
"lease",
"least",
"leave",
"legal",
"lemon",
"level",
"light",
"light",
"light",
"limbo",
"limit",
"limit",
"liver",
"lobby",
"local",
"local",
"logic",
"loose",
"loser",
"louse",
"lousy",
"lover",
"lower",
"loyal",
"lucky",
"lunch",
"lying",
"lyric",
"madam",
"mafia",
"magic",
"magic",
"major",
"major",
"maker",
"manly",
"maple",
"march",
"march",
"marry",
"match",
"match",
"maybe",
"mayor",
"medal",
"media",
"melon",
"mercy",
"merge",
"merry",
"messy",
"metal",
"meter",
"minor",
"minus",
"missy",
"mixer",
"model",
"moist",
"money",
"month",
"moose",
"moral",
"moron",
"motel",
"motor",
"mount",
"mouse",
"mouth",
"movie",
"mummy",
"mural",
"music",
"nacho",
"naive",
"naked",
"nanny",
"nasty",
"needy",
"nerve",
"never",
"niece",
"night",
"ninja",
"ninth",
"noble",
"noise",
"north",
"notch",
"novel",
"nurse",
"nurse",
"occur",
"ocean",
"offer",
"offer",
"often",
"olive",
"onion",
"opera",
"order",
"order",
"organ",
"other",
"other",
"ought",
"outer",
"owner",
"paint",
"paint",
"panda",
"panic",
"pants",
"paper",
"party",
"party",
"pasta",
"patch",
"patch",
"pause",
"peace",
"peach",
"penis",
"penny",
"petty",
"phase",
"phone",
"phony",
"phony",
"photo",
"piano",
"piece",
"piggy",
"pilot",
"pinch",
"pitch",
"pitch",
"pizza",
"place",
"place",
"plain",
"plane",
"plant",
"plant",
"plate",
"point",
"point",
"poker",
"porch",
"pound",
"pound",
"power",
"power",
"prank",
"press",
"press",
"price",
"prick",
"pride",
"prime",
"print",
"print",
"prize",
"proof",
"proud",
"prove",
"psych",
"puffy",
"punch",
"punch",
"puppy",
"purse",
"pussy",
"queen",
"queer",
"quest",
"quick",
"quiet",
"quiet",
"quite",
"quote",
"quote",
"rabbi",
"radio",
"raise",
"raise",
"rally",
"ranch",
"range",
"razor",
"reach",
"react",
"ready",
"rebel",
"refer",
"rehab",
"relax",
"rhyme",
"right",
"right",
"right",
"right",
"risky",
"river",
"roast",
"robot",
"rodeo",
"rough",
"round",
"round",
"round",
"round",
"route",
"rumor",
"rusty",
"sadly",
"saint",
"salad",
"salsa",
"salty",
"sauce",
"scale",
"scare",
"scare",
"scarf",
"scary",
"scene",
"scent",
"scoop",
"scoop",
"score",
"score",
"scout",
"screw",
"screw",
"scrub",
"scuba",
"seize",
"semen",
"sense",
"sense",
"serve",
"setup",
"seven",
"sewer",
"shack",
"shake",
"shall",
"shame",
"shape",
"shape",
"share",
"share",
"shark",
"sharp",
"shave",
"sheep",
"sheet",
"shelf",
"shell",
"shift",
"shine",
"shiny",
"shirt",
"shock",
"shock",
"shoot",
"short",
"shout",
"shove",
"shred",
"shush",
"sight",
"silly",
"since",
"siren",
"sixth",
"sixty",
"skate",
"skill",
"skirt",
"skull",
"slave",
"sleep",
"sleep",
"slice",
"slice",
"slide",
"slide",
"smack",
"small",
"smart",
"smash",
"smell",
"smell",
"smile",
"smile",
"smoke",
"smoke",
"snack",
"snake",
"sneak",
"sniff",
"sober",
"solar",
"solid",
"solve",
"sorry",
"sorry",
"sound",
"sound",
"south",
"space",
"spank",
"spare",
"spare",
"speak",
"speed",
"spell",
"spell",
"spend",
"sperm",
"spice",
"spicy",
"spill",
"spine",
"split",
"spoil",
"spoon",
"sport",
"spray",
"spray",
"squad",
"staff",
"stage",
"stain",
"stair",
"stall",
"stamp",
"stand",
"stand",
"stare",
"start",
"start",
"state",
"steak",
"steal",
"steam",
"steam",
"steel",
"steer",
"stick",
"stick",
"still",
"sting",
"stink",
"stock",
"stomp",
"stone",
"stool",
"store",
"storm",
"storm",
"story",
"strap",
"straw",
"strip",
"study",
"study",
"stuff",
"stuff",
"stunt",
"style",
"sugar",
"suite",
"super",
"sushi",
"swear",
"sweat",
"sweat",
"sweep",
"sweet",
"swell",
"swell",
"swing",
"swing",
"swipe",
"sword",
"syrup",
"table",
"tacky",
"tango",
"taste",
"taste",
"tasty",
"teach",
"tease",
"teens",
"teeny",
"tempt",
"tense",
"tenth",
"thank",
"theft",
"their",
"theme",
"there",
"there",
"these",
"thick",
"thief",
"thigh",
"thing",
"think",
"third",
"those",
"three",
"throw",
"thumb",
"tiger",
"tight",
"tired",
"title",
"toast",
"toast",
"today",
"today",
"tooth",
"topic",
"total",
"touch",
"touch",
"tough",
"towel",
"tower",
"toxic",
"track",
"track",
"trade",
"trade",
"trail",
"train",
"train",
"trash",
"trash",
"treat",
"treat",
"trial",
"tribe",
"trick",
"trick",
"troll",
"troop",
"truck",
"truly",
"trunk",
"trust",
"trust",
"truth",
"tummy",
"tumor",
"tweet",
"twice",
"twist",
"twist",
"uncle",
"under",
"union",
"until",
"upper",
"upset",
"upset",
"urban",
"urine",
"usual",
"valid",
"value",
"value",
"vault",
"vegan",
"video",
"virus",
"visit",
"visit",
"vodka",
"voice",
"vomit",
"vomit",
"voter",
"wacky",
"wagon",
"waist",
"waste",
"waste",
"watch",
"watch",
"water",
"water",
"weigh",
"weird",
"whack",
"whale",
"wheel",
"where",
"where",
"which",
"while",
"while",
"whine",
"white",
"whole",
"whore",
"whose",
"witch",
"witty",
"woman",
"world",
"worry",
"worry",
"worth",
"wound",
"wreck",
"wreck",
"wrist",
"write",
"wrong",
"yikes",
"young",
"yours",
"youth",
"yummy"
  ]